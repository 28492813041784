export enum PlatformManagementTab {
  PRICE_RULES = 'price-rules',
  MINIMAL_PRICE = 'minimal-price',
}

export enum VendorManagementTab {
  ADD_VENDOR = 'add-vendor',
  MAIN_DATA = 'main-data',
  CLIENT_PAY_METHODS = 'client-pay-methods',
  DISABLE_DSITE = 'disable-delivery-site',
  CATALOG_UPLOAD = 'catalog-upload',
}

export enum DeliverySiteManagementTab {
  ADD_DELIVERY_SITE = 'add-delivery-site',
  MAIN_DATA = 'main-data',
}

export enum ClientManagementTab {
  ADD_CLIENT = 'add-client',
  MAIN_DATA = 'main-data',
  DETAILS = 'details',
}

export enum RouteVal {
  CLIENTS_MANAGEMENT = 'clients-management',
  VENDORS_MANAGEMENT = 'vendors-management',
  DS_MANAGEMENT = 'delivery-sites-management',
  USERS_MANAGEMENT = 'users-management',
  PAYMENTS_MANAGEMENT = 'payments-management',
}
