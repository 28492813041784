import { StyledComponent } from '@emotion/styled'
import { Button, ButtonProps, styled } from '@mui/material'

export const boxStyle = {
  border: 1,
  borderRadius: 0.5,
  borderColor: 'grey.200',
  display: 'flex',
  alignItems: 'stretch',
  width: 'fit-content',
  flexDirection: 'row',
}

export const boxVerticalStyle = {
  borderTop: 1,
  borderColor: 'grey.200',
  display: 'flex',
  alignItems: 'stretch',
  width: 'fit-content',
  flexDirection: 'column',
}

export const StyledGActionBtn: StyledComponent<ButtonProps> = styled(Button)<ButtonProps>(({ theme }) =>
  theme.unstable_sx({
    p: 1.5,
    borderRadius: 0,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    minWidth: 'unset',
    ml: 0.5,
    px: 1,
    height: '100%',
  }),
)

export const StyledGVActionBtn: StyledComponent<ButtonProps> = styled(Button)<ButtonProps>(({ theme }) =>
  theme.unstable_sx({
    p: 1.5,
    borderRadius: 0,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    minWidth: 'unset',
    width: '100%',
  }),
)

export const progressStyle = {
  height: 2,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
}
