import TouchApp from '@mui/icons-material/TouchApp'
import { AvatarImg, DailyPrice } from 'core/components'
import { ValueRange } from 'core/consts'
import { Item } from 'core/types'
import { formatPricePerUnit } from 'core/utils/formatUtils'
import { TFunction } from 'i18next'
import noImage from 'items/images/noImage.png'

type Actions = {
  handleSelect: (el: Item) => void
}

export const mapVendorProductsToSwap = (data: Item[], action?: Actions) => {
  const mappedData = data.map(el => {
    const mappedActions = action
      ? [
          {
            title: 'select',
            icon: <TouchApp />,
            callback: () => action!.handleSelect(el),
          },
        ]
      : []

    return {
      rowId: el.id,
      data: {
        img: (
          <AvatarImg
            imgUrl={el.images && el.images.length > 0 ? el.images[0].uri : null}
            img={noImage}
            name={el.name}
            sx={{ width: 40, height: 40 }}
          />
        ),
        name: el.name,
        price: el.isDailyPrice ? <DailyPrice /> : formatPricePerUnit(el.price, el.unit),
        vatPercent: el.vatPercent + '%',
        quantity: el.stock === ValueRange.MAX_POSITIVE_VALUE ? 1000 : el.stock,
      },
      actions: mappedActions,
    }
  })
  return mappedData
}

export const mapVendorProductsToSwapColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('vendor.catalog.name'), id: 'name' },
  { title: t('basket.priceNet'), id: 'price' },
  { title: t('acceptOrder.vat'), id: 'vatPercent' },
  { title: t('vendor.catalog.quantity'), id: 'quantity' },
]
