import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@mui/material'

interface DailyPriceProps {
  title?: string
}

export const DailyPrice = ({ title }: DailyPriceProps) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={title ? title : t('common.dailyPriceBasicInfo')}>
      <Typography sx={{ cursor: 'default', color: 'warning.main' }} variant="subtitle2">
        {t('common.dailyPrice')}
      </Typography>
    </Tooltip>
  )
}
