import { Grid, Tooltip, Typography } from '@mui/material'

import AvatarImg from '../AvatarImg'

export interface ProductAndVendorNameProps {
  productName: string
  vendorName: string
  vendorLogoUrl: string
}

export const ProductAndVendorName = ({ productName, vendorName, vendorLogoUrl }: ProductAndVendorNameProps) => {
  return (
    <Grid container gap={0.5}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{productName}</Typography>
      </Grid>
      <Tooltip title={vendorName} placement="bottom-start">
        <Grid display="flex" alignItems="center" item xs={12} gap={1}>
          <AvatarImg imgUrl={vendorLogoUrl} name={vendorName} sx={{ width: 32, height: 32 }} />
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '11rem',
              cursor: 'default',
              fontSize: '0.75rem',
            }}
            variant="body2"
          >
            {vendorName}
          </Typography>
        </Grid>
      </Tooltip>
    </Grid>
  )
}
