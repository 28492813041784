import { ReactNode, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Add, PriorityHigh, Remove } from '@mui/icons-material'
import { Box, Button, FormHelperText, InputAdornment, LinearProgress, Tooltip } from '@mui/material'
import { ProductUnit } from 'core/consts'

import { TextField } from '../TextField'
import { StyledGActionBtn, StyledGVActionBtn, boxStyle, boxVerticalStyle, progressStyle } from './styles'

export interface AddToBasketProps {
  stock?: number
  tooltipTitle?: string
  name?: string
  value?: number
  disabled?: boolean
  icon?: ReactNode
  // onAction if action is triggered by user clicking btn
  onAction?: (quantity: number) => void
  // onInputAction if action is triggered when user changes input or clicks -/+
  onInputAction?: (quantity: number) => Promise<number>
  unit?: ProductUnit
  min?: number
  inline?: boolean
  variableWeightProduct?: boolean
}

export const AddToBasket = memo(
  ({
    onAction,
    stock,
    tooltipTitle,
    value,
    name = 'addToBasket',
    onInputAction,
    disabled,
    icon,
    unit = ProductUnit.Piece,
    min,
    inline,
    variableWeightProduct,
  }: AddToBasketProps) => {
    const { t } = useTranslation()
    const [amount, setAmount] = useState<string>(value !== undefined ? value.toString() : '0')
    const [updating, setUpdating] = useState(false)

    const maxStock = stock || 1000

    const handleChange = (val: string | number) => {
      setAmount(() => {
        const allowDecimal = unit !== ProductUnit.Piece
        const sanitizedVal = sanitizeValue(val.toString(), allowDecimal)

        return parseAndLimitValue(sanitizedVal, maxStock, min ?? 1)
      })
    }

    const handleAction = () => {
      if (typeof onAction === 'function') {
        const val = amount === '0' ? (min ? min : 1) : Number(amount)
        setAmount(val.toString())
        onAction(val)
      }
    }

    const handleDecrease = () => {
      setAmount(val => String(Math.round(Number(Number(val) - 1) * 100) / 100))
    }

    const handleIncrease = () => {
      setAmount(val => (val === '0' ? (min ? min.toString() : '1') : String(Math.round(Number(Number(val) + 1) * 100) / 100)))
    }

    useEffect(() => {
      value !== undefined && setAmount(value.toString())
    }, [value])

    useEffect(() => {
      if (typeof onInputAction === 'function' && value !== Number(amount)) {
        setUpdating(true)
        const delayDebounceFn = setTimeout(() => {
          const onActionRes = onInputAction(Number(amount))
          onActionRes
            .then((val: number) => {
              setAmount(val.toString())
              setUpdating(false)
            })
            .catch((val: number) => {
              setAmount(val.toString())
              setUpdating(false)
            })
        }, 800)
        return () => {
          clearTimeout(delayDebounceFn)
          setUpdating(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, value])

    const minVal = min && (
      <>
        <FormHelperText error={amount !== '0' && Number(amount) < Number(min)}>
          {t('common.minimal', { val: `${min} ${t(`product.unitValShort.${unit}`)}` })}
        </FormHelperText>
        {Number(stock) <= Number(min) - 1 && <FormHelperText>{t('orders.notEnoughInStockToOrder')}</FormHelperText>}
      </>
    )

    const addToCartDisabled = Boolean(stock && stock <= Number(min) - 1)

    return (
      <Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Box sx={inline ? boxVerticalStyle : boxStyle}>
            <Box position="relative">
              <Box display="inline-flex" py={1} px={1}>
                <Tooltip title={t('common.decrease')}>
                  <Box component="span" display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{ p: 0.2, minWidth: 'unset', mr: 0.5, borderRadius: 2 }}
                      disabled={(stock && stock <= 0) || Number(amount) < (min ? min + 1 : 1)}
                      onClick={handleDecrease}
                    >
                      <Remove />
                    </Button>
                  </Box>
                </Tooltip>

                <TextField
                  value={amount.toString()}
                  type="text"
                  onChangeOverride={handleChange}
                  name={`${name}-amount`}
                  variant="standard"
                  inputProps={{ max: maxStock }}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t(`product.unitValShort.${unit}`)}</InputAdornment>,
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && typeof onAction === 'function' && !addToCartDisabled) {
                      e.preventDefault()
                      handleAction()
                    }
                  }}
                  sx={{ minWidth: inline ? '3rem' : '5rem', width: '100%', '& input': { textAlign: 'center' } }}
                />
                <Tooltip title={t('common.increase')}>
                  <Box component="span" display="flex" alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{ p: 0.2, minWidth: 'unset', ml: 1, borderRadius: 2 }}
                      disabled={(stock && stock <= 0) || Number(stock) - Number(amount) < 1}
                      onClick={handleIncrease}
                    >
                      <Add />
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
              {updating && <LinearProgress sx={progressStyle} />}
            </Box>
            {inline && (
              <Box m={1.5} mt={0}>
                {minVal}
              </Box>
            )}
            {typeof onAction === 'function' && icon && (
              <Tooltip title={(stock && stock === 0) || !tooltipTitle ? '' : tooltipTitle}>
                <span>
                  {inline ? (
                    <StyledGVActionBtn variant="contained" onClick={handleAction} disabled={addToCartDisabled}>
                      {icon}
                    </StyledGVActionBtn>
                  ) : (
                    <StyledGActionBtn variant="contained" onClick={handleAction} disabled={addToCartDisabled}>
                      {icon}
                    </StyledGActionBtn>
                  )}
                </span>
              </Tooltip>
            )}
          </Box>
          {typeof onInputAction === 'function' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Tooltip title={t('common.variableWeightProduct')}>
                <PriorityHigh sx={{ fontSize: 32, color: 'warning.main', visibility: variableWeightProduct ? 'visible' : 'hidden' }} />
              </Tooltip>
            </Box>
          )}
        </Box>
        {!inline && minVal}
      </Box>
    )
  },
)

export const sanitizeValue = (value: string, allowDecimal: boolean) => {
  // Remove all special characters except for commas and dots and Replace all commas with dots
  const regex = allowDecimal ? /[^0-9,.]/g : /[^0-9]/g
  const sanitized = value.replace(regex, '')

  return allowDecimal ? sanitized.replace(/,/g, '.') : sanitized
}

export const parseAndLimitValue = (value: string, maxStock: number, minStock: number) => {
  if (value === '' || value === '0') return '0'

  // important to handle e.g 2.
  if (/^\d+[.]$/.test(value)) return value

  const parsedVal = Math.round(Number(parseFloat(value).toFixed(2)) * 100) / 100

  if (parsedVal < minStock) return minStock.toString()
  if (parsedVal > maxStock) return maxStock.toString()

  return parsedVal.toString()
}
