import { Chip } from '@mui/material'
import { AvatarImg, DailyPrice, ProductAndVendorName } from 'core/components'
import { Item, PaginatedResult } from 'core/types'
import { formatPricePerUnit } from 'core/utils'
import { TFunction } from 'i18next'
import noImage from 'items/images/noImage.png'

export const mapCatalogData = (data: PaginatedResult<Item>, t: TFunction) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.id,
      data: {
        img: <AvatarImg imgUrl={el.images && el.images.length > 0 ? el.images[0].uri : null} img={noImage} name={el.name} />,
        name: <ProductAndVendorName productName={el.name} vendorLogoUrl={el.vendorLogoUrl} vendorName={el.vendorName} />,
        category: el.category.name,
        price: el.isDailyPrice ? <DailyPrice /> : formatPricePerUnit(el.price, el.unit),
        quantity:
          el.stock > 0 ? (
            <Chip color="success" variant="outlined" label={t('orders.inStock')} />
          ) : (
            <Chip color="error" variant="outlined" label={t('orders.outOfStock')} />
          ),
        orderCount: el.orderCount,
      },
    }
  })

  return mappedData
}

export const mapCatalogColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('orders.name'), id: 'name' },
  { title: t('orders.category'), id: 'category' },
  { title: t('basket.priceNet'), id: 'price' },
  { title: t('orders.available'), id: 'quantity' },
  { title: t('orders.ordered'), id: 'orderCount' },
]

export const catalogCellStyles = [
  {
    id: 'price',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
]
