import { Edit } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, Chip, Tooltip, Typography } from '@mui/material'
import { AvatarImg, DailyPrice } from 'core/components'
import { ValueRange } from 'core/consts'
import { Item, PaginatedResult } from 'core/types'
import { formatPricePerUnit } from 'core/utils'
import { TFunction } from 'i18next'
import noImage from 'items/images/noImage.png'

type Actions = {
  handleEdit: (el: Item) => void
}

export const mapVendorCatalog = (data: PaginatedResult<Item>, action: Actions, t: TFunction) => {
  const mappedData = data.items.map(el => {
    return {
      rowId: el.id,
      data: {
        img: (
          <AvatarImg
            imgUrl={el.images && el.images.length > 0 ? el.images[0].uri : null}
            img={noImage}
            name={el.name}
            sx={{ width: 40, height: 40 }}
          />
        ),
        name: el.name,
        category: (
          <Box>
            <Typography variant="inherit">{el.category.name}</Typography>
            <Typography variant="body2" color="text.secondary" mt={1}>
              {el.category.subcategories[0].name}
            </Typography>
          </Box>
        ),
        tags: (
          <Typography component="div" display="flex" gap={1} flexWrap="wrap" mt={1}>
            {el.tags?.filter(el => el !== '').length > 0 ? el.tags.map(tag => <Chip key={tag} label={tag} size="small" />) : '-'}
          </Typography>
        ),
        price: (
          <Box>
            <Typography variant="subtitle1">{el.isDailyPrice ? <DailyPrice /> : formatPricePerUnit(el.price, el.unit)}</Typography>
            <Typography variant="body2" mt={1}>
              VAT {el.vatPercent} %
            </Typography>
            <Typography variant="body2" color="text.secondary" mt={1}>
              Min {el.minCartAmount} {t(`product.unitValShort.${el.unit}`)}
            </Typography>
          </Box>
        ),
        quantity: (
          <>
            {el.stock > 0 ? (
              <Typography variant="inherit">
                {el.stock === ValueRange.MAX_POSITIVE_VALUE ? 1000 : el.stock} {t(`product.unitValShort.${el.unit}`)}
              </Typography>
            ) : (
              <Tooltip title={t('orders.outOfStock')}>
                <RemoveCircleOutlineIcon color="error" />
              </Tooltip>
            )}
          </>
        ),
        isActive: el.isActive ? <CheckCircleIcon color="success" /> : <RemoveCircleOutlineIcon color="disabled" />,
      },
      actions: [
        {
          title: t('common.edit'),
          icon: <Edit />,
          callback: () => action.handleEdit(el),
        },
      ],
    }
  })

  return mappedData
}

export const mapVendorCatalogColumns = (t: TFunction) => [
  { title: '', id: 'img' },
  { title: t('orders.name'), id: 'name' },
  { title: t('productDetails.category'), id: 'category' },
  { title: t('productDetails.tags'), id: 'tags' },
  { title: t('orders.price'), id: 'price' },
  { title: t('orders.available'), id: 'quantity' },
  { title: t('productDetails.isActive'), id: 'isActive' },
  { title: t('common.edit'), id: 'edit', align: 'right' },
]

export const vendorCatalogCellStyles = [
  {
    id: 'price',
    styles: {
      whiteSpace: 'nowrap',
    },
  },
]
