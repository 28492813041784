import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Reply } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Avatar, Box, Button, Chip, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import { useAuth } from 'auth/contexts/AuthProvider'
import { ContentItem, MainPaper, MainTable, NotReadyWrapper, PageTitle } from 'core/components'
import { OrderStatus, PaymentTypeVal, ProductUnit, UserRole } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { ProductOrder } from 'core/types'
import { statusDisplayProperties } from 'core/types/statusDisplayProperties'
import { formatDateAndTime, getPaymentMethodIcon } from 'core/utils'
import { formatPrice } from 'core/utils/formatUtils'
import dayjs from 'dayjs'
import { getEstimatedDeliveryDate } from 'orders/helpers'
import {
  useAcceptOrder,
  useAddCommentToOrder,
  useGetOrderDetails,
  usePostCommitOrderChanges,
  usePostOrderIssue,
  useProcessOrder,
} from 'orders/hooks'
import { DialogTypes } from 'vendor/types/types'

import { VendorOrderDetailsDialogs } from './Dialogs/VendorOrderDetailsDialogs'
import { mapProductOrder, mapProductOrderColumns } from './mapVendorOrdersDetails'

const VendorOrdersDetails = () => {
  const { userInfo } = useAuth()
  const roles = userInfo?.roles
  const { uuid: orderId } = useParams()
  const { data: order, refetch: refetchOrder, isError, isSuccess, isLoading: isLoadingOrder } = useGetOrderDetails(orderId ?? '')
  const { t } = useTranslation()
  const { processOrder, isLoading: isStartingProcessOrder } = useProcessOrder()
  const { acceptOrder, isLoading } = useAcceptOrder()
  const { postAddCommentToOrder, isLoading: isAddingComment } = useAddCommentToOrder()
  const { postCommitOrderChanges } = usePostCommitOrderChanges()
  const { postOrderIssue } = usePostOrderIssue()

  const isOnlinePayment = order?.paymentMethod === PaymentTypeVal.OnlinePayment || order?.paymentMethod === PaymentTypeVal.Spingo

  const [openDialog, setOpenDialog] = useState<DialogTypes | null>(null)

  const [selectedProduct, setSelectedProduct] = useState<ProductOrder | null>(null)

  const handleOpenOrderDialog = (dialogType: DialogTypes, product: ProductOrder | null) => {
    setOpenDialog(dialogType)
    setSelectedProduct(product)
  }
  const handleCloseOrderDialog = () => {
    setOpenDialog(null)
    setSelectedProduct(null)
    refetchOrder()
  }

  const snackbar = useSnackbar()
  const [comment, setComment] = useState('')

  const handleAddComment = async () => {
    await postAddCommentToOrder(
      { orderId, comment },
      {
        onSuccess: () => {
          snackbar.success(t('orders.addCommentSuccess'))
          setComment('')
          refetchOrder()
        },
        onError: () => {
          snackbar.error(t('orders.addCommentError'))
        },
      },
    )
  }

  const canAcceptOrder = () => {
    return [OrderStatus.Placed, OrderStatus.Paid, OrderStatus.Processing].includes(order?.status as OrderStatus)
  }

  const canStartProcessingOrder = () => {
    return [OrderStatus.Placed, OrderStatus.Paid].includes(order?.status as OrderStatus)
  }

  const canAddProduct = () => {
    return (
      [OrderStatus.Placed, OrderStatus.Paid, OrderStatus.Processing].includes(order?.status as OrderStatus) &&
      [PaymentTypeVal.CashOnDelivery, PaymentTypeVal.DeferredPayment].includes(order?.paymentMethod as PaymentTypeVal)
    )
  }

  const estimatedDeliveryDate = useMemo(() => {
    if (!order) {
      return { finalDate: '' }
    }
    return getEstimatedDeliveryDate(order.vendor.deliveryConditions, order.firstOrderBetweenClientAndVendor, dayjs(order.orderDate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.vendor.deliveryConditions, order?.firstOrderBetweenClientAndVendor])

  if (!order) {
    return null
  }

  const notOverriddenOrRemovedProducts = order.productOrders.filter(product => !product.isOverriden && !product.isRemoved)

  const hasActiveVariableWeightProduct = notOverriddenOrRemovedProducts.some(product => product.unit !== ProductUnit.Piece)

  const isOverridenOrRemoved = () => order?.productOrders?.some(product => product.isOverriden || product.isRemoved) || false
  const { color, messageKey } = statusDisplayProperties[order?.status] || { color: 'black', messageKey: 'order.status.unknown' }

  const handleAcceptOrder = async () => {
    if (orderId !== undefined) {
      await acceptOrder(orderId)
    }
    refetchOrder()
  }

  const handleStartProcessing = () => {
    if (orderId !== undefined) {
      processOrder(
        { id: orderId },
        {
          onSuccess: () => {
            snackbar.success(t('vendors.changes.sentSuccessfully'))
            refetchOrder()
          },
          onError: () => {
            snackbar.error(t('vendors.changes.sendingError'))
          },
        },
      )
    }
  }

  const handleCommitChanges = async () => {
    await postCommitOrderChanges(
      { orderId },
      {
        onSuccess: () => {
          snackbar.success(t('vendors.changes.sentSuccessfully'))
          refetchOrder()
        },
        onError: () => {
          snackbar.error(t('vendors.changes.sendingError'))
        },
      },
    )
  }

  const handleOrderIssues = async () => {
    await postOrderIssue(
      { orderId },
      {
        onSuccess: () => {
          snackbar.success(t('vendors.changes.sentSuccessfully'))
          refetchOrder()
        },
        onError: () => {
          snackbar.error(t('vendors.changes.sendingError'))
        },
      },
    )
  }

  return (
    <NotReadyWrapper isLoading={isLoadingOrder} isSuccess={isSuccess} isError={isError}>
      <Box pt={2}>
        <PageTitle
          title={t('acceptOrder.orderDetailsFor')}
          subtitle={
            <Typography variant="h3" color="primary" fontWeight={700}>
              {order.client.name}
            </Typography>
          }
        />
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Chip label={t(messageKey)} sx={{ bgcolor: color, color: 'common.white' }} />
        <Button variant="outlined" color="error" onClick={() => handleOpenOrderDialog(DialogTypes.CHANGE_ORDER_STATUS, null)}>
          {t('order.changeStatus')}
        </Button>
      </Box>
      <MainPaper sx={{ my: 3, '& > *:not(:last-child)': { mb: 1.5 } }}>
        <ContentItem label={t('order.orderId')} value={order.friendlyOrderNumber} inline />
        <ContentItem label={t('order.placed')} value={formatDateAndTime(order.orderDate, true)} inline />
        <ContentItem label={t('order.lastUpdate')} value={formatDateAndTime(order.lastUpdateDate, true)} inline />
        <ContentItem
          label={t('order.paymentMethod')}
          value={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {getPaymentMethodIcon(order.paymentMethod)}
              {t(`payment.paymentsMethodVal.${order.paymentMethod}`)}
            </Box>
          }
          inline
        />
        <ContentItem label={t('order.estimatedDeliveryDate')} value={estimatedDeliveryDate.finalDate} inline />
        {/* comment left in the cart during creating an order */}
        {order.orderAnnotation?.trim().length > 0 && (
          <ContentItem
            sx={{ color: 'info.main' }}
            label={t('vendors.orderCommentMadeByClient')}
            value={order.orderAnnotation}
            inline={order.orderAnnotation.length < 220}
          />
        )}
        {hasActiveVariableWeightProduct && (
          <ContentItem
            sx={{ color: 'error.main' }}
            label={t('vendor.importantInfo')}
            value={t('vendor.variableWeightProductInfo')}
            inline
          />
        )}
      </MainPaper>
      <MainPaper>
        <MainTable
          columns={mapProductOrderColumns(t)}
          rows={mapProductOrder(order, t, {
            handleOpenOrderDialog,
          })}
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline', my: 4 }}>
          <Typography variant="h3">{t('acceptOrder.orderSummary')}:</Typography>
          <Box>
            <Typography variant="h2">{formatPrice(isOnlinePayment ? order.skaloTotalCost : order.totalCost)} </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {formatPrice(isOnlinePayment ? order.skaloTotalCostGross : order.totalCostGross, 'gross')}
            </Typography>
          </Box>
          {canAddProduct() && (
            <Button onClick={() => handleOpenOrderDialog(DialogTypes.ADD_PRODUCT, null)} sx={{ marginLeft: 'auto' }} variant="contained">
              {t('vendorOrders.addProduct')}
            </Button>
          )}
        </Box>
        <>
          <List sx={{ width: '100%', maxWidth: 600 }}>
            {order.comments
              .toSorted((a, b) => (dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? 1 : -1))
              .map(comment => (
                <ListItem key={comment.timestamp}>
                  <ListItemAvatar>
                    <Avatar>{`${comment.commenterFirstName.charAt(0)}${comment.commenterLastName.charAt(0)}`}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box display="flex" gap={2} mb={1} alignItems="center">
                        <Typography variant="h4">
                          {comment.commenterFirstName} {comment.commenterLastName}
                        </Typography>
                        <Typography variant="body2" color="grey">
                          {dayjs(comment.timestamp).fromNow()}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Typography variant="body2" color="text.primary" sx={{ wordWrap: 'break-word' }}>
                        {comment.comment}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
          </List>
          <Box mt={2} display="flex" justifyContent="space-between" gap={4}>
            <TextField value={comment} onChange={e => setComment(e.target.value)} placeholder={t('orders.addComment')} fullWidth />
            <LoadingButton variant="contained" size="small" onClick={handleAddComment} disabled={!comment.trim()} loading={isAddingComment}>
              {t('orders.addCommentToOrder')}
            </LoadingButton>
          </Box>
        </>
        <Box display="flex" gap={2} mt={3}>
          <Button
            startIcon={<Reply />}
            variant="outlined"
            component={Link}
            to={roles?.includes(UserRole.VENDOR) ? '/vendor-orders' : '/admin-orders'}
          >
            {t('userOrders.backToOrdersList')}
          </Button>
          {canStartProcessingOrder() && (
            <LoadingButton
              variant="contained"
              sx={{ bgcolor: 'info.dark', color: 'white' }}
              onClick={handleStartProcessing}
              loading={isStartingProcessOrder}
            >
              {t('vendorOrders.startProcessing')}
            </LoadingButton>
          )}

          {canAcceptOrder() && (
            <span>
              <Button variant="contained" color="success" disabled={isLoading || isOverridenOrRemoved()} onClick={handleAcceptOrder}>
                {isLoading ? t('vendorOrders.processingAccept') : t('vendorOrders.acceptOrder')}
              </Button>
            </span>
          )}
          {canAcceptOrder() && isOverridenOrRemoved() && (
            <Button variant="contained" color="success" sx={{ bgcolor: 'success.dark' }} onClick={handleCommitChanges}>
              {t('vendorOrders.acceptAndSend')}
            </Button>
          )}
          {order.status !== 'DeliveryIssue' && (
            <Button variant="contained" color="warning" onClick={handleOrderIssues}>
              {t('vendorOrders.orderIssue')}
            </Button>
          )}
        </Box>
      </MainPaper>
      {/* Dialogs */}
      <VendorOrderDetailsDialogs
        onClose={handleCloseOrderDialog}
        openDialog={openDialog}
        order={order}
        refetchOrder={refetchOrder}
        selectedProduct={selectedProduct}
      />
    </NotReadyWrapper>
  )
}

export default VendorOrdersDetails
